/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "KTechlab es un programa de simulación de circuitos electrónicos para Linux. Me recuerda al Cocodrile (para Windows) en el modo de usar pero este es mucho mas avanzado en algunos aspectos. Por ejemplo permite simular el uso de pics que podemos programar en C o ensamblador (Vale lo confieso a tanto no llego, pero ya llegaré), también podemos hacer diagramas de flujo. Para instalarlo simplemente lo busqué en el Synaptic. Aquí podéis ver una captura del programa."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2008/02/ktechlab1.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 128px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 79.6875%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtapIUP//EABgQAAMBAQAAAAAAAAAAAAAAAAABEQIQ/9oACAEBAAEFAllERC9//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERQVFhcf/aAAgBAQABPyGwjWNcCE8JP//aAAwDAQACAAMAAAAQTz//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QiIj/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QrKz/xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhMZFB8P/aAAgBAQABPxBxKDFaTRV4XgpY1vZ8UefnT//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"ktechlab1.jpg\"\n        title=\"ktechlab1.jpg\"\n        src=\"/static/9d0522fc926217e9a2dee62598fdade7/474e0/ktechlab1.thumbnail.jpg\"\n        srcset=\"/static/9d0522fc926217e9a2dee62598fdade7/474e0/ktechlab1.thumbnail.jpg 128w\"\n        sizes=\"(max-width: 128px) 100vw, 128px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Y os dejo un pequeño ", React.createElement(_components.a, {
    href: "http://blogdrake.net/node/4127"
  }, "tutorial"), " que he encontrado en la red. La verdad es que lo que mas me costó encontrar fueron las sondas de los osciloscopios, se encuentran el la categoría ouputs, la que mas utilizo, la sonda analógica de tensión, se llama Voltage Probe. Espero que os sea útil. Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
